console.log("js done");

import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import HystModal from "./modules/hystmodal.js";

// BURGER MENU
const btnBurger = document.querySelector(".m-burger-menu");
const btnCloseBurger = document.querySelector(".m-close-btn");
const burgerContent = document.querySelector(".b-header-nav ");

btnBurger.addEventListener("click", openMobile);
btnCloseBurger.addEventListener("click", function () {
  burgerContent.classList.remove("active-menu");
  btnBurger.children[0].classList.remove("opened");
});
function openMobile() {
  burgerContent.classList.add("active-menu");
}

// FOUNDATION
var app = {};

$(document).ready(function () {
  $(document).foundation();

  // $(".js-select").select2({
  //   placeholder: 'ТЕСТ',
  // });

  $(function () {
    $(document).on("click", ".js-select__input", function () {
      $(this).parents(".js-select").toggleClass("active");
    });
    $(document).mouseup(function (e) {
      // событие клика по веб-документу
      var div = $(".js-select.active"); // тут указываем ID элемента
      if (
        !div.is(e.target) && // если клик был не по нашему блоку
        div.has(e.target).length === 0
      ) {
        // и не по его дочерним элементам
        div.removeClass("active"); // скрываем его
      }
    });
    $(document).on("click", ".active .js-select__list span", function () {
      var id = $(this).data("id");
      var parent = $(this).parents(".js-select");
      parent.find(".js-select__input").text($(this).text());
      parent.find("input").val(id).attr("value", id);
      parent.removeClass("active");
    });
  });
});

// $(function () {

//   // app.topMenu();

// });

// MAIN BANNER
const swiperMainBanner = new Swiper(".l-main-banner", {
  loop: true,
  pagination: {
    el: ".l-main-banner__pagination",
  },
  autoplay: {
    delay: 2000,
  },
});

const swiperNewsMain = new Swiper(".swiper-news", {
  sliderPerView: "auto",
  spaceBetween: 40,
  navigation: {
    nextEl: ".swiper-news__btn-next",
    prevEl: ".swiper-news__btn-prev",
  },
});

let dpMin, dpMax;

dpMin = new AirDatepicker("#datepick-1", {
  // isMobile: true,
  // autoClose: true,
  onSelect({ date }) {
    dpMax.update({
      minDate: date,
    });
  },
});

dpMax = new AirDatepicker("#datepick-2", {
  onSelect({ date }) {
    dpMin.update({
      maxDate: date,
    });
  },
});

// vacancies open detail

const vacanciesOpenDetailBtn = document.querySelectorAll(".l-detail-open");

vacanciesOpenDetailBtn.forEach((el) => {
  el.addEventListener("click", openFadeDetail);
});

function openFadeDetail(el) {
  let btnTarget = el.currentTarget;
  btnTarget.offsetParent.children[1].classList.toggle("active");
  btnTarget.nextSibling.classList.toggle("active");
}

const popupVacancies = new HystModal({
  linkAttributeName: "data-hystmodal",
});

$(".input-file input[type=file]").on("change", function () {
  let file = this.files[0];
  $(this).next().html(file.name);
});

// // import main js
// mainSwiperBanner();
// mainCardSwiper();
// reverseSlider();

// $(document).ready(function () {
//   $(window).scroll(function () {
//     if ($(this).scrollTop() > 50) {
//       $("#back-to-top").fadeIn();
//     } else {
//       $("#back-to-top").fadeOut();
//     }
//   });
//   // scroll body to 0px on click
//   $("#back-to-top").click(function () {
//     $("body,html").animate(
//       {
//         scrollTop: 0,
//       },
//       400
//     );
//     return false;
//   });
// });

// // HEADER JS
// const headerLeftBtns = document.querySelector(".catalog-list-second");
// const headerDropdownContent = document.querySelectorAll(".catalog-content");
// const headerActiveLink = document.querySelectorAll(".catalog-list__link");

// const headerOpenDropdown = document.querySelector("#dropdown");
// const headerDropdown = document.querySelector(".dropdown-catalog ");

// function changeHeaderContent(event) {
//   headerActiveLink.forEach((el) => {
//     el.classList.remove("active");
//   });
//   headerDropdownContent.forEach((el) => {
//     el.classList.remove("active-content");
//   });
//   let btnData = event.target.dataset.headerLink;
//   document.querySelector("#" + btnData).classList.add("active-content");

//   event.target.classList.add("active");
// }
// headerLeftBtns.addEventListener("click", changeHeaderContent);

// headerOpenDropdown.addEventListener("click", function () {
//   headerDropdown.classList.toggle("active-dropdown");
//   if (headerDropdown.classList.length == 2) {
//     document.querySelector(".bg-dropdown").classList.add("active-bg");
//   } else {
//     document.querySelector(".bg-dropdown").classList.remove("active-bg");
//   }
// });
// document.querySelector(".bg-dropdown").addEventListener("click", function () {
//   headerDropdown.classList.remove("active-dropdown");
//   document.querySelector(".bg-dropdown").classList.remove("active-bg");
// });

// // REVERSE SECTION
// const reverseBtns = document.querySelectorAll(".l-reverse-btn");
// reverseBtns.forEach((el) => {
//   el.addEventListener("click", reverseEl);
// });

// // NEWS TABS
// const newsTabBtn = document.querySelectorAll(".l-tab-btn");

// newsTabBtn.forEach((el) => {
//   el.addEventListener("click", openTab);
// });

// // CONTACTS FADE EFFECT
// const contactsBtns = document.querySelectorAll(".l-conctacts-item ");

// contactsBtns.forEach((el) => {
//   el.addEventListener("click", openFade);
// });

// // PROJECT SECTION
// const projectsTabBtns = document.querySelectorAll(".l-tab-link");
// const projectsTabContent = document.querySelectorAll(".l-tab-project");

// projectsTabBtns.forEach((el) => {
//   el.addEventListener("click", openTabProjects);
// });

// function openTabProjects(el) {
//   let btnTarget = el.currentTarget;
//   let tab = btnTarget.dataset.project;
//   projectsTabBtns.forEach((el) => {
//     el.children[0].children[0].classList.remove("active");
//   });

//   projectsTabContent.forEach((el) => {
//     el.classList.remove("active-project-tab");
//   });
//   projectsTabBtns.forEach((el) => {
//     el.classList.remove("active-project-btn");
//   });

//   document.querySelector("#" + tab).classList.add("active-project-tab");
//   btnTarget.classList.add("active-project-btn");
//   if (btnTarget.children[0].children[0].classList.length == 2) {
//     btnTarget.children[0].children[0].classList.remove("active");
//   } else {
//     btnTarget.children[0].children[0].classList.add("active");
//   }
// }

// // footer tabs
// const footerTabBtn = document.querySelectorAll(".l-footer-tab__link");
// const footerTabContent = document.querySelectorAll(".l-footer-tab__content");

// footerTabBtn.forEach((el) => {
//   el.addEventListener("click", openFooterTab);
// });

// function openFooterTab(el) {
//   let btnTarget = el.currentTarget;
//   let tab = btnTarget.dataset.footerTab;

//   footerTabBtn.forEach((el) => {
//     el.classList.remove("active-footer-tab-btn");
//   });
//   footerTabContent.forEach((el) => {
//     el.classList.remove("active-footer-tab");
//   });
//   document.querySelector("#" + tab).classList.add("active-footer-tab");
//   btnTarget.classList.add("active-footer-tab-btn");
// }

// // CATALOG DETAIL PAGE - product tabs
// const producTabBtn = document.querySelectorAll(".l-product-tab__link");
// const productTabContent = document.querySelectorAll(".l-product-tab__content");

// producTabBtn.forEach((el) => {
//   el.addEventListener("click", openProductTab);
// });

// function openProductTab(el) {
//   let btnTarget = el.currentTarget;
//   let tab = btnTarget.dataset.productTab;

//   producTabBtn.forEach((el) => {
//     el.classList.remove("active-product-tab-btn");
//   });
//   productTabContent.forEach((el) => {
//     el.classList.remove("active-product-tab");
//   });
//   document.querySelector("#" + tab).classList.add("active-product-tab");
//   btnTarget.classList.add("active-product-tab-btn");
// }

// // Top menu
// // app.topMenu = function () {
// //   $(".dropdown").on("show.zf.dropdownMenu", function (ev, $el) {
// //     $el
// //       .css({
// //         display: "none",
// //       })
// //       .slideDown(700);
// //   });

// //   $(".dropdown").on("hide.zf.dropdownMenu", function (ev, $el) {
// //     $el.children("ul").css("display", "inherit").slideUp(300);
// //   });
// // };

// // Default setting
// // Foundation.DropdownMenu.defaults.closingTime = 10000;
// // Foundation.DropdownMenu.defaults.hoverDelay = 30000;

// // catalog js
// const asideFilter = document.querySelector(".l-filter");
// if (document.querySelector(".btn-open-filter")) {
//   document.querySelector(".btn-open-filter").addEventListener("click", () => {
//     asideFilter.classList.toggle("active-filter");
//   });
// }

// if (document.querySelector(".c-close-filter")) {
//   document.querySelector(".c-close-filter").addEventListener("click", () => {
//     asideFilter.classList.remove("active-filter");
//   });
// }

// // catalog detail JS
// if (document.querySelector(".l-product")) {
//   const productLowerImg = new Swiper(".l-detail-swiper-lower", {
//     // loop: true,
//     spaceBetween: 15,
//     slidesPerView: "auto",
//     // touchRatio: 0.2,
//     navigation: {
//       nextEl: ".l-detail-swiper-btn__next",
//       prevEl: ".l-detail-swiper-btn__prev",
//     },
//     breakpoints: {
//       768: {
//         spaceBetween: 20,
//       },
//       640: {
//         enabled: true,
//       },
//     },
//   });

//   const productTopImg = new Swiper(".l-detail-swiper-top", {
//     // loop: true,
//     slidesPerView: "auto",
//     thumbs: {
//       swiper: productLowerImg,
//     },
//     navigation: {
//       nextEl: ".l-catalog-detail__btn-next",
//       prevEl: ".l-catalog-detail__btn-prev",
//     },
//     breakpoints: {
//       640: {},
//     },
//   });
// }

// // Рекомендуемые
// let adviseSwiper = new Swiper(".js-product-advise", {
//   spaceBetween: 20,
//   slidesPerView: "auto",
//   enabled: true,
//   speed: 400,
//   breakpoints: {
//     950: {
//       enabled: false,
//     },
//   },
// });

// // basket order
// const checkboxOrder = document.querySelectorAll(".order-checkbox");

// checkboxOrder.forEach((el) => {
//   el.addEventListener("click", (el) => {
//     if (el.target.checked) {
//       el.target.parentElement.parentElement.classList.add("checked-border");
//     } else {
//       el.target.parentElement.parentElement.classList.remove("checked-border");
//     }
//   });
// });

// // premium swiper
// let premiumSwiper = new Swiper(".l-premium-banner", {
//   spaceBetween: 20,
//   slidesPerView: "auto",
//   // enabled: true,
//   // centeredSlides: true,
//   width: 310,
//   // touchReleaseOnEdges: false,
//   breakpoints: {
//     1440: {
//       // width: 450,
//     },
//     768: {
//       enabled: false,
//     },
//   },
// });

// // favorites swiper
// let favoritesSwiper = new Swiper(".l-favorites-swiper", {
//   spaceBetween: 15,
//   slidesPerView: "auto",
//   breakpoints: {
//     425: {
//       spaceBetween: 20,
//     },
//   },
// });

// // vacancies swiper
// let vacanciesSwiper = new Swiper(".l-vacancies-swiper ", {
//   spaceBetween: 20,
//   slidesPerView: "auto",
//   // enabled: true
// });

// // MAIN PAGE SWIPER NEWS BLOCK
// let mainNewsSwiperFirst = new Swiper(".swiper-main-news__first", {
//   spaceBetween: 10,
//   slidesPerView: "auto",
//   breakpoints: {
//     560: {
//       enabled: false,
//     },
//   },
// });

// let mainNewsSwiperSecond = new Swiper(".swiper-main-news__second", {
//   spaceBetween: 10,
//   slidesPerView: "auto",
//   breakpoints: {
//     560: {
//       enabled: false,
//     },
//   },
// });

// // main page blog
// let mainSwiperBlog = new Swiper(".l-swiper-blog__main-page", {
//   spaceBetween: 20,
//   slidesPerView: "auto",
//   breakpoints: {
//     1430: {
//       enabled: false,
//     },
//   },
// });

// // l-basket js

// //aside js
// const asideUserList = document.querySelector(".l-user-list");
// if (document.querySelector(".l-user-list")) {
//   document
//     .querySelector(".l-aside-dropdown__btn")
//     .addEventListener("click", () => {
//       asideUserList.classList.toggle("active-aside");
//     });
// }

// if (asideUserList) {
//   document.querySelector(".c-close-sort").addEventListener("click", () => {
//     asideUserList.classList.remove("active-aside");
//   });
// }

// // MODALS

// const dotsContent = new HystModal({
//   linkAttributeName: "data-hystmodal",
//   catchFocus: true,
//   closeOnEsc: true,
//   backscroll: true,
// });

// const popupPhone = new HystModal({
//   linkAttributeName: "data-popup-phone",
//   catchFocus: true,
//   closeOnEsc: true,
//   backscroll: true,
// });
